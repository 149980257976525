.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  min-height: 42px;
  height: 42px;
  max-height: 42px;
  font-size: 14px;
  max-width: 250px;
  width: 100%;
  background-color: transparent;
}

.input-label {
  z-index: 1;
  position: absolute;
  top: 8.5px;
  left: 10px;
  font-size: 15px;
  color: #313d4f;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.tm4-input {
  flex: 1;
  z-index: 2;
  border: 1px solid #313d4f;
  border-radius: 6px;
  padding: 0 10px 0 10px;
  height: 42px;
  max-height: 42px;
  font-family: -apple-system, "SF Pro Text", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", "sans-serif";
  background-color: transparent;
  font-size: 14px;
  color: #313d4f;
}

.tm4-input:focus {
  outline-style: none;
  border-width: 2px;
}

.input-container > .tm4-input:valid ~ .input-label,
.tm4-input:focus ~ .input-label {
  position: absolute;
  top: 2px;
  font-size: 10px;
  font-weight: 600;
}

.input-container > .tm4-input:focus,
.tm4-input:valid {
  padding-top: 8px;
}
