@import "~antd/dist/antd.css";
@import "./fonts.css";

/* App general styles */

html,
body {
  /* stylelint-disable-next-line */
  font-family: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  height: auto;
  background-color: inherit;
}

a {
  color: #0070c9;
}

a:hover {
  text-decoration: underline;
  color: #0070c9;
}
